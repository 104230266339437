:root {
  --theme-primary-color: #0445fe;
  --theme-primary-light-color: #0445fe20;
  --theme-on-primary-color: #ffffff;

  --theme-background-color: #f5f6f8;
  --theme-on-background-color: #181818;

  --theme-surface-color: #ffffff;
  --theme-on-surface-color: #181818;

  --theme-default-text-color: #181818;

  --theme-heading-1-text-color: #181818;
  --theme-heading-2-text-color: #505050;
  --theme-subtitle-1-text-color: #707070;
  --theme-subtitle-2-text-color: #929292;

  --theme-disabled-color: #dddddd;  
  --theme-divider-color: #bebebe;
  --theme-border-color: #e5e7eb;

  --theme-danger-color: #ff0000;
  --theme-success-color: #00ff00;
  --theme-info-color: #00bcd4;
  --theme-warning-color: #ff8181;
}
