.MessageAttachments {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    flex-wrap: wrap;

    .MessageAttachmentHolder {
        .MessageAttachment {

            &.video-thumbnail,
            &.image {
                // max-height: 208px;
                // min-height: 58px;
                width: 208px;
                overflow: hidden;
                border-radius: 4px;
                background-repeat: no-repeat;
                transition-duration: 0.1s;
                background-size: cover;
                background-position: center;
                transition-property: opacity, box-shadow;
            }

            &.video-thumbnail {
                min-height: 208px;
                background-color: black;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .video-play-button {
                    color: #fff6;
                    font-size: 24px;
                }

                &:hover {
                    .video-play-button {
                        color: #fff;
                    }
                }
            }

            &.other {
                border: 1px solid var(--theme-on-surface-color);
                border-radius: 4px;
                padding: 12px;
                display: flex;
                align-items: center;
                max-width: 208px;
                color: var(--theme-on-surface-color);

                .details-holder {
                    overflow: hidden;
                }

                img {
                    margin-right: 8px;
                }

                .font-semibold {
                    white-space: nowrap;
                }
            }

            margin-bottom: 8px;

            &:hover+.remove-attachment-button {
                visibility: visible;
            }
        }

        .remove-attachment-button {
            position: absolute;
            top: -6px;
            right: 0px;
            width: 21px;
            height: 21px;
            font-size: large;
            background-color: #aaa;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            visibility: hidden;

            &:hover {
                visibility: visible;
                background-color: white;
            }
        }

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.date-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    color: #999;

    .date {
        border-radius: 12px;
    }
}