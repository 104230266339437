@keyframes ldio-yyy8w2muuv {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-yyy8w2muuv div {
  left: 88.825px;
  top: 51.425000000000004px;
  position: absolute;
  animation: ldio-yyy8w2muuv linear 0.3021148036253776s infinite;
  background: #31355f;
  width: 9.350000000000001px;
  height: 16.830000000000002px;
  border-radius: 3.0294000000000003px / 3.0294000000000003px;
  transform-origin: 4.675000000000001px 42.075px;
}
.ldio-yyy8w2muuv div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.28323262839879154s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.26435045317220546s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.24546827794561932s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.22658610271903323s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.20770392749244712s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.18882175226586104s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.16993957703927492s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.1510574018126888s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.13217522658610273s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.11329305135951662s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.09441087613293052s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.0755287009063444s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.05664652567975831s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.0377643504531722s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0188821752265861s;
  background: #31355f;
}
.ldio-yyy8w2muuv div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #31355f;
}
.loadingio-spinner-spinner-tjnezsqmvn {
  width: 187px;
  height: 187px;
  display: inline-block;
  overflow: hidden;
}
.ldio-yyy8w2muuv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yyy8w2muuv div {
  box-sizing: content-box;
}
