@import "variables";

.text-theme-primary {
    color: $pensilBlue;
}

.border-theme-primary {
    border-color: $pensilBlue;
}


.hover\:border-theme-primary:hover {
    border-color: $pensilBlue;
}

.bg-theme-primary {
    background-color: $pensilBlue;
}




.text-xxs {
    font-size: 0.65rem;
}

/* Animations */

.spinner {
    animation: spinner 0.75s ease-in-out infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animated {
    transition: all 0.4s;
}

ol,
ul {
    list-style: initial;
    margin: initial;
    padding: 0.75rem;
    padding-left: 2rem;
}

ol {
    list-style: decimal;
}

// date picker style
.react-datepicker-wrapper {
    width: unset !important;
}